import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle: "SpotOn Case Study | Chase Field",
    pageDescription:
      "See why the fifth-largest baseball stadium in the nation chose SpotOn technology when it came time to upgrade their enterprise POS system.",
    title: 'Technology made for the big leagues',
    subTitle: `How Chase Field is upping its game with SpotOn's enterprise solutions.`,
    businessName: "Chase Field",
    industry: "Enterprise, sports & entertainment",
    location: "Phoenix, Arizona",
    products: [
      spotonProducts.ENTERPRISE_POS,
      spotonProducts.ONLINE_ORDERING,
      spotonProducts.KITCHEN_DISPLAYS,
      spotonProducts.HANDHELDS,
    ],
    description:
      "As the fifth-largest baseball stadium in the nation (48,000+ capacity), Chase Field is the place to see the Arizona Diamondbacks (MLB), numerous college sporting events, and nationally touring music concerts. And after testing POS systems for two months, operators decided to deploy SpotOn solutions throughout the stadium.",
    quoteData: {
      imgName: "chase-field.png",
      quote: `“The ability to watch their ops team react to real-life situations beyond just showing us what the technology was going to look like, built our confidence in them and solidified our decision to move forward with SpotOn.”`,
      personName: "Kenny Farrell",
      personTitle: "Senior Vice President, Marketing & Business Analytics of the Arizona Diamondbacks",
    },
    goal: `Having struggled with 5 different food & beverage technology vendors, Chase Field needed a unified solution for point-of-sale, hawking and vending, mobile, self-checkout, concessions, and premium areas. To this goal, they tested SpotOn technology in one of the busiest sections of the stadium.`,
    solution: `SpotOn deployed POS terminals in premium areas and concessions along with handhelds for hawking/ vending and in-seat service. Chase Field also enabled mobile express pickup via Kitchen Display Systems, while SpotOn's API integrates with Venuetize to give fans mobile ordering through the MLB Ballpark App.`,
    results: {
      title: `The results`,
      stats: [
        { title: `370+`, description: `terminals deployed in premium areas and concessions` },
        {
          title: `50+`,
          description: `handhelds deployed for hawking, vending, and in-seat service`,
        },
        { title: `40+`, description: `Kitchen Display Systems deployed for mobile pickup, in-seat, and restaurant kitchens` },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
        title: "A POS system that knocks it out of the park",
        imageName: "durham-bulls.png",
        industry: resourceIndustries.ENTERPRISE,
        tag: resourceTags.CASE_STUDY,
        date: "2022-03-31",
        href: "/case-studies/durham-bulls",
        goals: [
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
          resourceGoals.STREAMLINE_OPERATIONS,
        ],
    },
    {
        title: "Helping fans see more touchdowns",
        imageName: "louisiana-state-university.png",
        industry: resourceIndustries.ENTERPRISE,
        tag: resourceTags.CASE_STUDY,
        date: "2022-03-10",
        href: "/case-studies/louisiana-state-university",
        goals: [
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
          resourceGoals.STREAMLINE_OPERATIONS,
        ],
    },
    {
        title: "Going live with multiple venues on one platform",
        imageName: "live-nation.png",
        industry: resourceIndustries.ENTERPRISE,
        tag: resourceTags.CASE_STUDY,
        date: "2022-02-17",
        href: "/case-studies/live-nation",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
  ];
  